// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-hosting-js": () => import("./../../../src/pages/services/hosting.js" /* webpackChunkName: "component---src-pages-services-hosting-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-services-monthly-website-packages-js": () => import("./../../../src/pages/services/monthly-website-packages.js" /* webpackChunkName: "component---src-pages-services-monthly-website-packages-js" */),
  "component---src-pages-services-seo-js": () => import("./../../../src/pages/services/seo.js" /* webpackChunkName: "component---src-pages-services-seo-js" */),
  "component---src-pages-services-web-design-js": () => import("./../../../src/pages/services/web-design.js" /* webpackChunkName: "component---src-pages-services-web-design-js" */)
}

